import React, { useEffect, useState } from 'react';
import './FallbackLoader.css';
import { Fade } from 'react-awesome-reveal';

export default function FallbackLoader() {
	const [show, setShow] = useState(false);
	useEffect(() => {
		let timeout = setTimeout(() => setShow(true), 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);
	return (
		<>
			{show && (
				<Fade>
					<div className="fallback-loader-div">
					<div class="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</Fade>
			)}
		</>
	);
}
